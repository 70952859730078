import logo300 from './Logo_LM_Sebastian.png';
import logoLideres from './Logo_LM_LOS300.png';
import './App.css';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import MillfoodsLogo from './millfoods-icon.svg'

function App() {
  
  return (
    <div className = 'App-header bg-cover 'style={{
      backgroundImage:'url(/Back_RA_Los300_Back.png)',
      }}>
      <img src={logoLideres} width="80%" />
      <p className='font-face-gm  text-xs' >LÍDERES MÁS INFLUYENTES DE MÉXICO</p>
      <div className='flex'>
        <p className=' text-[10px] italic ml-[130px] '>by</p>
        <img src={MillfoodsLogo} className='mb-20 w-16 mt-1'/>
      </div>
      
      <a href='https://webxr.run/2oOD1zd0M6exN' className='' >
          <button className='mb-5 h-[50px]'>
            <span className='bg-green-400 hover:bg-green-900 text-black font-bold py-2 px-3 border-b-4 border-green-700 hover:border-green-500 rounded-l-full'>
              <ArrowOutwardIcon className='pb-1 pl-1'/>
              </span>  
            <span className='bg-red-400 hover:bg-red-00 text-black font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded-r-full'>CLICK</span></button>
      </a>
          <p className='font-face-gm text-xs'>REALIDAD AUMENTADA</p>

        <img  src={logo300} width="60%" className='mt-40'/>
    </div>

    
  );
}

export default App;
